export enum DeviceSystems {
  IOS = "ios",
  COMMON = "common",
}

export enum Countries {
  "by" = "Belarus",
  "cy" = "Cyprus",
}

export enum Level {
  REGION = "region",
  SILVER = "silver",
  GOLD = "gold",
  PLATINUM = "platinum",
  VIP = "vip"
}
export enum Brand {
  allsports = "allsports",
  sportbenefit = "sportbenefit",
}

export enum Env {
  production = "production",
  development = "development",
}

export type BrandStrings = keyof typeof Brand;

export enum LocalesEnum {
  "en-cy" = "en-cy",
  "en-by" = "en-by",
  "ru-by" = "ru-by",
}

export enum User {
  companies = "companies",
  members = "members",
  partners = "partners",
}

export type UserStrings = keyof typeof User;

export interface Benefit {
  level: string;
  description: string;
  benefits: string[];
}
export interface ILocales {
  code: string;
  iso: string;
  dir: string;
  file: string;
  country: string;
  language: string;
}

export interface IFormFields {
  name: string;
  companyName: string;
  phone: string;
  email: string;
  location: string;
  question: string;
  processPersonalData: string;
  smsCode: string;
}

export type GetOfferForm = Pick<
  IFormFields,
  "name" | "companyName" | "email" | "phone" | "processPersonalData"
>;
export type BecomePartnerForm = Pick<
  IFormFields,
  | "name"
  | "companyName"
  | "email"
  | "phone"
  | "processPersonalData"
  | "location"
>;

export type AskQuestionForm =
  | Pick<IFormFields, "name" | "question" | "phone">
  | "processPersonalData";

export type ContactForm = Pick<
  IFormFields,
  "name" | "email" | "phone" | "processPersonalData"
>;

export enum CookieOptionsKeys {
  Technical = "technical",
  Analytical = "analytical",
}

export type CookieOptionsValues = Record<CookieOptionsKeys, boolean>;

export interface IDocumentsData {
  [key: string]: {
    title: string;
    items: string[];
  };
}

export enum CooperationTypes {
  PARTNER = "becomePartner",
  CONNECT_COMPANY = "getOffer",
}

export enum SuccessModalTypes {
  CONNECT_COMPANY = "company",
  PARTNER = "partner",
  QUESTION = "question",
}
