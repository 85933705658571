<script setup lang="ts">
import { getStatistics } from "./api/statisticsRequest";
import { Content, DEFAULT_CONTENT, useContentStore } from "./stores/content";
import { OfficeContact } from "./types/content";
import { getSuppliersPreviewByCountry } from "~/api/suppliersByCountryPreviewRequest";

const { saveContent } = useContentStore();

const { locale, locales } = useI18n();
const { brand } = useRuntimeConfig().public;

// Default values have been set in the i18n config,
// so we can be sure that the value exists
const { country } = locales.value.find((e) => e.code === locale.value)!;

const { data } = await useAsyncData("content", async () => {
  const [statistics, feedback, officeContactData, videoLinkData, facilitiesInCities] =
    await Promise.all([
      getStatistics(locale.value),
      queryContent(
        `/sections/${brand}/${country.toLowerCase()}/feedback-section`
      ).findOne(),
      queryContent(
        `/sections/${brand}/${country.toLowerCase()}/contacts-section`
      ).findOne(),
      queryContent(
        `/sections/${brand}/${country.toLowerCase()}/video-section`
      ).findOne(),
      getSuppliersPreviewByCountry(country),
    ]);

  const content: Content = {
    levels: statistics?.levels ?? DEFAULT_CONTENT.levels,
    companies: feedback?.companies ?? DEFAULT_CONTENT.companies,
    officeContact: (officeContactData?.officeContact as OfficeContact) ?? DEFAULT_CONTENT.officeContact,
    applinks: officeContactData?.appLinks ?? DEFAULT_CONTENT.applinks,
    socialNetworks: officeContactData?.socialNetworks ?? DEFAULT_CONTENT.socialNetworks,
    videoLink: videoLinkData?.videoLink ?? DEFAULT_CONTENT.videoLink,
    facilitiesInCities: facilitiesInCities ?? DEFAULT_CONTENT.facilitiesInCities,
  };

  return content;
});

data.value && saveContent(data.value);
</script>

<template>
  <NuxtLayout>
    <NuxtPage :content="data" />
  </NuxtLayout>
</template>
