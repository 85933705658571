import { IScrollSliderItem } from "~/components/slider/ScrollSlider.vue";
import {
  AppLink,
  OfficeContact,
  SocialNetwork,
} from "~/types/content";
import { LevelsList } from "~/types/statistics";
import type { SuppliersPreviewByCountry } from "~/types/supplier.interface";
import type { ISupplierPreview } from "~/types/map.interface";

const STORE_NAMESPACE = "content";

export interface Content {
  levels: LevelsList;
  companies: IScrollSliderItem[];
  officeContact: OfficeContact;
  applinks: AppLink[];
  socialNetworks: SocialNetwork[];
  videoLink: string;
  facilitiesInCities: SuppliersPreviewByCountry;
}

export const DEFAULT_CONTENT: Content = {
  levels: {
    region: {
      facility_amount: 0,
      activity_amount: 0,
    },
    silver: {
      facility_amount: 0,
      activity_amount: 0,
    },
    gold: {
      facility_amount: 0,
      activity_amount: 0,
    },
    platinum: {
      facility_amount: 0,
      activity_amount: 0,
    },
  },
  companies: [
    {
      name: "",
      imageUrl: "",
      icon: "",
      description: "",
      id: "",
      feedback: "",
      link: "",
      image: {
        modernFormatImage: {
          "desktop-path": "",
          "desktop-path-retina": "",
        },
        fallbackFormatImage: {
          "desktop-path": "",
          "desktop-path-retina": "",
        },
        alt: "",
        width: 0,
        height: 0,
        loading: "eager",
      },
    },
  ],
  officeContact: {
    phone: "",
    email: "",
    address: "",
    workTime: [""],
    officeMarker: [0, 0],
  },
  applinks: [
    {
      link: "",
      icon: "",
      device: "",
    },
  ],
  socialNetworks: [
    {
      link: "",
      value: "",
    },
  ],
  videoLink: "",
  facilitiesInCities: {
    minsk: [
      {
        address: "",
        name: "",
        id: "",
        coordinates: [0, 0]
      },
    ]
  }
};

export const useContentStore = defineStore(STORE_NAMESPACE, {
  state() {
    return DEFAULT_CONTENT;
  },

  getters: {
    getContacts(state) {
      return state.officeContact;
    },
    getVideoLink(state) {
      return state.videoLink;
    },
    getLevels(state) {
      return state.levels;
    },
    getAppLinks(state) {
      return state.applinks;
    },
    getSocialNetworks(state) {
      return state.socialNetworks;
    },
    getFacilitiesInCities(state) {
      return state.facilitiesInCities;
    },
    getAllFacilities(state) {
      const allFacilities: ISupplierPreview[] = [];
      Object.keys(state.facilitiesInCities).forEach((city) => {
        allFacilities.push(...state.facilitiesInCities[city]);
      });

      return allFacilities;
    }
  },
  actions: {
    saveContent(content: Content) {
      this.$state = {...content}
    },
  },
});
