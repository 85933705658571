import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as indexLLPjmkxWemMeta } from "/opt/atlassian/pipelines/agent/build/pages/[documents]/[document]/index.vue?macro=true";
import { default as indexkZYAzkZXmHMeta } from "/opt/atlassian/pipelines/agent/build/pages/[documents]/index.vue?macro=true";
import { default as index8Dq4djMZpDMeta } from "/opt/atlassian/pipelines/agent/build/pages/app/index.vue?macro=true";
import { default as _91id_93Y8sk8sH4UpMeta } from "/opt/atlassian/pipelines/agent/build/pages/blog/[id].vue?macro=true";
import { default as indexLAlQRJLYeOMeta } from "/opt/atlassian/pipelines/agent/build/pages/blog/index.vue?macro=true";
import { default as companiesPHgLjlkIWqMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies.vue?macro=true";
import { default as contactshp1YG4sx3EMeta } from "/opt/atlassian/pipelines/agent/build/pages/contacts.vue?macro=true";
import { default as index2222bKANCeMeta } from "/opt/atlassian/pipelines/agent/build/pages/facilities-table/index.vue?macro=true";
import { default as indext15xQkr66UMeta } from "/opt/atlassian/pipelines/agent/build/pages/facilities/index.vue?macro=true";
import { default as holder_45app_45agreementEpOS8ApMniMeta } from "/opt/atlassian/pipelines/agent/build/pages/holder-app-agreement.vue?macro=true";
import { default as holder_45app_45rulesrF5Luw3JUvMeta } from "/opt/atlassian/pipelines/agent/build/pages/holder-app-rules.vue?macro=true";
import { default as levelshgIskYfxVmMeta } from "/opt/atlassian/pipelines/agent/build/pages/levels.vue?macro=true";
import { default as partnersxX7AlUWNMtMeta } from "/opt/atlassian/pipelines/agent/build/pages/partners.vue?macro=true";
import { default as processing_45personal_45datamAQlxcNziVMeta } from "/opt/atlassian/pipelines/agent/build/pages/processing-personal-data.vue?macro=true";
import { default as providing_45payment_45service_45rules2Ik3SI2q6jMeta } from "/opt/atlassian/pipelines/agent/build/pages/providing-payment-service-rules.vue?macro=true";
import { default as public_45offerENgAonpJCAMeta } from "/opt/atlassian/pipelines/agent/build/pages/public-offer.vue?macro=true";
import { default as rulesfsSuyvlREFMeta } from "/opt/atlassian/pipelines/agent/build/pages/rules.vue?macro=true";
export default [
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexLLPjmkxWemMeta?.name ?? "documents-document___ru-by",
    path: indexLLPjmkxWemMeta?.path ?? "/ru-by/:documents()/:document()",
    meta: indexLLPjmkxWemMeta || {},
    alias: indexLLPjmkxWemMeta?.alias || [],
    redirect: indexLLPjmkxWemMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[documents]/[document]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLLPjmkxWemMeta?.name ?? "documents-document___en-by",
    path: indexLLPjmkxWemMeta?.path ?? "/en-by/:documents()/:document()",
    meta: indexLLPjmkxWemMeta || {},
    alias: indexLLPjmkxWemMeta?.alias || [],
    redirect: indexLLPjmkxWemMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[documents]/[document]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkZYAzkZXmHMeta?.name ?? "documents___ru-by",
    path: indexkZYAzkZXmHMeta?.path ?? "/ru-by/:documents()",
    meta: indexkZYAzkZXmHMeta || {},
    alias: indexkZYAzkZXmHMeta?.alias || [],
    redirect: indexkZYAzkZXmHMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[documents]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkZYAzkZXmHMeta?.name ?? "documents___en-by",
    path: indexkZYAzkZXmHMeta?.path ?? "/en-by/:documents()",
    meta: indexkZYAzkZXmHMeta || {},
    alias: indexkZYAzkZXmHMeta?.alias || [],
    redirect: indexkZYAzkZXmHMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[documents]/index.vue").then(m => m.default || m)
  },
  {
    name: index8Dq4djMZpDMeta?.name ?? "app___ru-by",
    path: index8Dq4djMZpDMeta?.path ?? "/ru-by/app",
    meta: index8Dq4djMZpDMeta || {},
    alias: index8Dq4djMZpDMeta?.alias || [],
    redirect: index8Dq4djMZpDMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: index8Dq4djMZpDMeta?.name ?? "app___en-by",
    path: index8Dq4djMZpDMeta?.path ?? "/en-by/app",
    meta: index8Dq4djMZpDMeta || {},
    alias: index8Dq4djMZpDMeta?.alias || [],
    redirect: index8Dq4djMZpDMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Y8sk8sH4UpMeta?.name ?? "blog-id___ru-by",
    path: _91id_93Y8sk8sH4UpMeta?.path ?? "/ru-by/blog/:id()",
    meta: _91id_93Y8sk8sH4UpMeta || {},
    alias: _91id_93Y8sk8sH4UpMeta?.alias || [],
    redirect: _91id_93Y8sk8sH4UpMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Y8sk8sH4UpMeta?.name ?? "blog-id___en-by",
    path: _91id_93Y8sk8sH4UpMeta?.path ?? "/en-by/blog/:id()",
    meta: _91id_93Y8sk8sH4UpMeta || {},
    alias: _91id_93Y8sk8sH4UpMeta?.alias || [],
    redirect: _91id_93Y8sk8sH4UpMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLAlQRJLYeOMeta?.name ?? "blog___ru-by",
    path: indexLAlQRJLYeOMeta?.path ?? "/ru-by/blog",
    meta: indexLAlQRJLYeOMeta || {},
    alias: indexLAlQRJLYeOMeta?.alias || [],
    redirect: indexLAlQRJLYeOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLAlQRJLYeOMeta?.name ?? "blog___en-by",
    path: indexLAlQRJLYeOMeta?.path ?? "/en-by/blog",
    meta: indexLAlQRJLYeOMeta || {},
    alias: indexLAlQRJLYeOMeta?.alias || [],
    redirect: indexLAlQRJLYeOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: companiesPHgLjlkIWqMeta?.name ?? "companies___ru-by",
    path: companiesPHgLjlkIWqMeta?.path ?? "/ru-by/companies",
    meta: companiesPHgLjlkIWqMeta || {},
    alias: companiesPHgLjlkIWqMeta?.alias || [],
    redirect: companiesPHgLjlkIWqMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies.vue").then(m => m.default || m)
  },
  {
    name: companiesPHgLjlkIWqMeta?.name ?? "companies___en-by",
    path: companiesPHgLjlkIWqMeta?.path ?? "/en-by/companies",
    meta: companiesPHgLjlkIWqMeta || {},
    alias: companiesPHgLjlkIWqMeta?.alias || [],
    redirect: companiesPHgLjlkIWqMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies.vue").then(m => m.default || m)
  },
  {
    name: contactshp1YG4sx3EMeta?.name ?? "contacts___ru-by",
    path: contactshp1YG4sx3EMeta?.path ?? "/ru-by/contacts",
    meta: contactshp1YG4sx3EMeta || {},
    alias: contactshp1YG4sx3EMeta?.alias || [],
    redirect: contactshp1YG4sx3EMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactshp1YG4sx3EMeta?.name ?? "contacts___en-by",
    path: contactshp1YG4sx3EMeta?.path ?? "/en-by/contacts",
    meta: contactshp1YG4sx3EMeta || {},
    alias: contactshp1YG4sx3EMeta?.alias || [],
    redirect: contactshp1YG4sx3EMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: index2222bKANCeMeta?.name ?? "facilities-table___ru-by",
    path: index2222bKANCeMeta?.path ?? "/ru-by/facilities-table",
    meta: index2222bKANCeMeta || {},
    alias: index2222bKANCeMeta?.alias || [],
    redirect: index2222bKANCeMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/facilities-table/index.vue").then(m => m.default || m)
  },
  {
    name: index2222bKANCeMeta?.name ?? "facilities-table___en-by",
    path: index2222bKANCeMeta?.path ?? "/en-by/facilities-table",
    meta: index2222bKANCeMeta || {},
    alias: index2222bKANCeMeta?.alias || [],
    redirect: index2222bKANCeMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/facilities-table/index.vue").then(m => m.default || m)
  },
  {
    name: indext15xQkr66UMeta?.name ?? "facilities___ru-by",
    path: indext15xQkr66UMeta?.path ?? "/ru-by/facilities",
    meta: indext15xQkr66UMeta || {},
    alias: indext15xQkr66UMeta?.alias || [],
    redirect: indext15xQkr66UMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/facilities/index.vue").then(m => m.default || m)
  },
  {
    name: indext15xQkr66UMeta?.name ?? "facilities___en-by",
    path: indext15xQkr66UMeta?.path ?? "/en-by/facilities",
    meta: indext15xQkr66UMeta || {},
    alias: indext15xQkr66UMeta?.alias || [],
    redirect: indext15xQkr66UMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/facilities/index.vue").then(m => m.default || m)
  },
  {
    name: holder_45app_45agreementEpOS8ApMniMeta?.name ?? "holder-app-agreement___ru-by",
    path: holder_45app_45agreementEpOS8ApMniMeta?.path ?? "/ru-by/holder-app-agreement",
    meta: holder_45app_45agreementEpOS8ApMniMeta || {},
    alias: holder_45app_45agreementEpOS8ApMniMeta?.alias || [],
    redirect: holder_45app_45agreementEpOS8ApMniMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/holder-app-agreement.vue").then(m => m.default || m)
  },
  {
    name: holder_45app_45agreementEpOS8ApMniMeta?.name ?? "holder-app-agreement___en-by",
    path: holder_45app_45agreementEpOS8ApMniMeta?.path ?? "/en-by/holder-app-agreement",
    meta: holder_45app_45agreementEpOS8ApMniMeta || {},
    alias: holder_45app_45agreementEpOS8ApMniMeta?.alias || [],
    redirect: holder_45app_45agreementEpOS8ApMniMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/holder-app-agreement.vue").then(m => m.default || m)
  },
  {
    name: holder_45app_45rulesrF5Luw3JUvMeta?.name ?? "holder-app-rules___ru-by",
    path: holder_45app_45rulesrF5Luw3JUvMeta?.path ?? "/ru-by/holder-app-rules",
    meta: holder_45app_45rulesrF5Luw3JUvMeta || {},
    alias: holder_45app_45rulesrF5Luw3JUvMeta?.alias || [],
    redirect: holder_45app_45rulesrF5Luw3JUvMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/holder-app-rules.vue").then(m => m.default || m)
  },
  {
    name: holder_45app_45rulesrF5Luw3JUvMeta?.name ?? "holder-app-rules___en-by",
    path: holder_45app_45rulesrF5Luw3JUvMeta?.path ?? "/en-by/holder-app-rules",
    meta: holder_45app_45rulesrF5Luw3JUvMeta || {},
    alias: holder_45app_45rulesrF5Luw3JUvMeta?.alias || [],
    redirect: holder_45app_45rulesrF5Luw3JUvMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/holder-app-rules.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___ru-by",
    path: indexOD9t3F2bSJMeta?.path ?? "/ru-by",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___en-by",
    path: indexOD9t3F2bSJMeta?.path ?? "/en-by",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: levelshgIskYfxVmMeta?.name ?? "levels___ru-by",
    path: levelshgIskYfxVmMeta?.path ?? "/ru-by/levels",
    meta: levelshgIskYfxVmMeta || {},
    alias: levelshgIskYfxVmMeta?.alias || [],
    redirect: levelshgIskYfxVmMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/levels.vue").then(m => m.default || m)
  },
  {
    name: levelshgIskYfxVmMeta?.name ?? "levels___en-by",
    path: levelshgIskYfxVmMeta?.path ?? "/en-by/levels",
    meta: levelshgIskYfxVmMeta || {},
    alias: levelshgIskYfxVmMeta?.alias || [],
    redirect: levelshgIskYfxVmMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/levels.vue").then(m => m.default || m)
  },
  {
    name: partnersxX7AlUWNMtMeta?.name ?? "partners___ru-by",
    path: partnersxX7AlUWNMtMeta?.path ?? "/ru-by/partners",
    meta: partnersxX7AlUWNMtMeta || {},
    alias: partnersxX7AlUWNMtMeta?.alias || [],
    redirect: partnersxX7AlUWNMtMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersxX7AlUWNMtMeta?.name ?? "partners___en-by",
    path: partnersxX7AlUWNMtMeta?.path ?? "/en-by/partners",
    meta: partnersxX7AlUWNMtMeta || {},
    alias: partnersxX7AlUWNMtMeta?.alias || [],
    redirect: partnersxX7AlUWNMtMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: processing_45personal_45datamAQlxcNziVMeta?.name ?? "processing-personal-data___ru-by",
    path: processing_45personal_45datamAQlxcNziVMeta?.path ?? "/ru-by/processing-personal-data",
    meta: processing_45personal_45datamAQlxcNziVMeta || {},
    alias: processing_45personal_45datamAQlxcNziVMeta?.alias || [],
    redirect: processing_45personal_45datamAQlxcNziVMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/processing-personal-data.vue").then(m => m.default || m)
  },
  {
    name: processing_45personal_45datamAQlxcNziVMeta?.name ?? "processing-personal-data___en-by",
    path: processing_45personal_45datamAQlxcNziVMeta?.path ?? "/en-by/processing-personal-data",
    meta: processing_45personal_45datamAQlxcNziVMeta || {},
    alias: processing_45personal_45datamAQlxcNziVMeta?.alias || [],
    redirect: processing_45personal_45datamAQlxcNziVMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/processing-personal-data.vue").then(m => m.default || m)
  },
  {
    name: providing_45payment_45service_45rules2Ik3SI2q6jMeta?.name ?? "providing-payment-service-rules___ru-by",
    path: providing_45payment_45service_45rules2Ik3SI2q6jMeta?.path ?? "/ru-by/providing-payment-service-rules",
    meta: providing_45payment_45service_45rules2Ik3SI2q6jMeta || {},
    alias: providing_45payment_45service_45rules2Ik3SI2q6jMeta?.alias || [],
    redirect: providing_45payment_45service_45rules2Ik3SI2q6jMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/providing-payment-service-rules.vue").then(m => m.default || m)
  },
  {
    name: providing_45payment_45service_45rules2Ik3SI2q6jMeta?.name ?? "providing-payment-service-rules___en-by",
    path: providing_45payment_45service_45rules2Ik3SI2q6jMeta?.path ?? "/en-by/providing-payment-service-rules",
    meta: providing_45payment_45service_45rules2Ik3SI2q6jMeta || {},
    alias: providing_45payment_45service_45rules2Ik3SI2q6jMeta?.alias || [],
    redirect: providing_45payment_45service_45rules2Ik3SI2q6jMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/providing-payment-service-rules.vue").then(m => m.default || m)
  },
  {
    name: public_45offerENgAonpJCAMeta?.name ?? "public-offer___ru-by",
    path: public_45offerENgAonpJCAMeta?.path ?? "/ru-by/public-offer",
    meta: public_45offerENgAonpJCAMeta || {},
    alias: public_45offerENgAonpJCAMeta?.alias || [],
    redirect: public_45offerENgAonpJCAMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/public-offer.vue").then(m => m.default || m)
  },
  {
    name: public_45offerENgAonpJCAMeta?.name ?? "public-offer___en-by",
    path: public_45offerENgAonpJCAMeta?.path ?? "/en-by/public-offer",
    meta: public_45offerENgAonpJCAMeta || {},
    alias: public_45offerENgAonpJCAMeta?.alias || [],
    redirect: public_45offerENgAonpJCAMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/public-offer.vue").then(m => m.default || m)
  },
  {
    name: rulesfsSuyvlREFMeta?.name ?? "rules___ru-by",
    path: rulesfsSuyvlREFMeta?.path ?? "/ru-by/rules",
    meta: rulesfsSuyvlREFMeta || {},
    alias: rulesfsSuyvlREFMeta?.alias || [],
    redirect: rulesfsSuyvlREFMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: rulesfsSuyvlREFMeta?.name ?? "rules___en-by",
    path: rulesfsSuyvlREFMeta?.path ?? "/en-by/rules",
    meta: rulesfsSuyvlREFMeta || {},
    alias: rulesfsSuyvlREFMeta?.alias || [],
    redirect: rulesfsSuyvlREFMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/rules.vue").then(m => m.default || m)
  }
]