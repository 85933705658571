import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/atlassian/pipelines/agent/build/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_UYiXMU8ZyN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0sentry_client_xzaPeUvBvQ from "/opt/atlassian/pipelines/agent/build/plugins/0sentry.client.ts";
import clickOutside_ZB3Eg0wOVk from "/opt/atlassian/pipelines/agent/build/plugins/clickOutside.ts";
import googleAnalytics_client_AE3XgnrlDf from "/opt/atlassian/pipelines/agent/build/plugins/googleAnalytics.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  i18n_yfWm7jX06p,
  plugin_client_UYiXMU8ZyN,
  chunk_reload_client_UciE0i6zes,
  _0sentry_client_xzaPeUvBvQ,
  clickOutside_ZB3Eg0wOVk,
  googleAnalytics_client_AE3XgnrlDf
]